import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth } from "../../../../store/contextAPI/authContext";
import { useHistory, useLocation } from "react-router";
import { getLocalStorage } from "./../../../../utils/common-function";
import useUser from "../../../../services/hooks/useUser";
import { ILocationState } from "./types";
import mixpanel from "../../../../services/mixpanelService";

const useBio = () => {
  const history = useHistory();
  const location = useLocation<ILocationState>();
  const { user, setUserBioContext, setSocialHandlers, setProfileComplete } = useAuth();
  const userId = getLocalStorage("userId");
  const { updateUserProfile } = useUser(userId);
  const userNameContext = useMemo<string>(() => `${user.firstName} ${user.lastName}`, [user.firstName, user.lastName]);
  const userBioContext = useMemo<string>(() => user.bio, [user.bio]);

  const [isBioModalOpen, setBioModalOpen] = useState<boolean>(false);
  const [isSocialHandlersModalOpen, setSocialHandlerOpen] = useState<boolean>(false);
  const [userBio, setUserBio] = useState<string>("");
  const [instagramId, setInstagramId] = useState<string>("");
  const [tiktokId, setTiktokId] = useState<string>("");
  const [youtube, setYoutube] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);

  const [instagramIdAfterDone, setInstagramIdAfterDone] = useState<string>(user.instagramId);
  const [tiktokIdAfterDone, setTiktokIdAfterDone] = useState<string>(user.tiktokId);
  const [youtubeAfterDone, setYoutubeAfterDone] = useState<string>(user.youtube);

  useEffect(() => {
    mixpanel.track("login-onboarding-bio-viewed");
  }, []);

  const onOpenCloseBioModal = useCallback(() => {
    mixpanel.track("login-onboarding-add-bio-clicked");
    setBioModalOpen((prevState) => !prevState);
    setUserBio(userBio);
  }, []);

  const onDoneHandlerForBio = useCallback(() => {
    mixpanel.track("login-onboarding-add-bio-continue-clicked");
    userBio && setUserBioContext(userBio);
    setBioModalOpen(false);
  }, [userBio, setUserBioContext]);

  const onDoneHandlerForSocialHandlers = useCallback(() => {
    mixpanel.track("login-onboarding-add-socials-continue-clicked");
    const isAnySocialHandles: boolean = instagramId !== user.instagramId || tiktokId !== user.tiktokId || youtube !== user.youtube;
    if (isAnySocialHandles) {
      setSocialHandlers(instagramId, tiktokId, youtube);
      setInstagramIdAfterDone(() => instagramId);
      setTiktokIdAfterDone(() => tiktokId);
      setYoutubeAfterDone(() => youtube);
    }
    setSocialHandlerOpen(false);
  }, [instagramId, tiktokId, youtube, setSocialHandlers, user]);

  const onOpenCloseSocialMediaModal = useCallback(() => {
    mixpanel.track("login-onboarding-add-socials-clicked");
    setInstagramId(() => instagramIdAfterDone);
    setTiktokId(() => tiktokIdAfterDone);
    setYoutube(() => youtubeAfterDone);
    setSocialHandlerOpen((prevState) => !prevState);
  }, [instagramIdAfterDone, tiktokIdAfterDone, youtubeAfterDone]);

  const onChangeBio = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserBio(event.target.value);
  }, []);

  const onChangeInstagramId = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setInstagramId(event.target.value);
  }, []);

  const onChangeTiktokId = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setTiktokId(event.target.value);
  }, []);

  const onChangeYoutube = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setYoutube(event.target.value);
  }, []);

  const onUpdateProfileSuccess = useCallback(() => {
    // Push the new route without allowing back navigation
    history.push({ pathname: location.state?.from || "/", state: location.state });

    // Use the browser's native history API to manipulate the history state
    window.history.pushState(null, "", window.location.href);

    // Listen for the `popstate` event to handle the back button behavior
    const handleBackButton = (event: any) => {
      event.preventDefault();
      window.history.pushState(null, "", window.location.href); // Push state again to block back navigation
    };

    // Add the listener when the component mounts
    window.addEventListener("popstate", handleBackButton);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [history, location.state]);

  const onUpdateProfileError = useCallback(() => {
    console.log("Error occured !!!");
  }, []);

  const onContinueHandler = useCallback(async () => {
    setLoading(true);
    const { bio, youtube, instagramId, tiktokId } = user;
    updateUserProfile(
      {
        ...(!!youtube?.length && { youtubeUsername: youtube }),
        ...(!!tiktokId?.length && { tiktokUsername: tiktokId }),
        ...(!!instagramId?.length && { instagramUsername: instagramId }),
        description: bio
      },
      onUpdateProfileSuccess,
      onUpdateProfileError
    );
    setProfileComplete();
    setLoading(false);
  }, [user, user.password]);

  const isSocialHandlerButtonVisible = useMemo<boolean>(
    () => instagramId.length !== 0 && tiktokId.length !== 0 && youtube.length !== 0,
    [instagramId, tiktokId, youtube]
  );

  return {
    user,
    isLoading,
    userBioContext,
    userNameContext,
    instagramIdAfterDone,
    tiktokIdAfterDone,
    youtubeAfterDone,
    userBio,
    instagramId,
    tiktokId,
    youtube,
    isBioModalOpen,
    isSocialHandlersModalOpen,
    onChangeInstagramId,
    onChangeTiktokId,
    onContinueHandler,
    onChangeYoutube,
    onOpenCloseBioModal,
    onChangeBio,
    onOpenCloseSocialMediaModal,
    isSocialHandlerButtonVisible,
    onDoneHandlerForBio,
    onDoneHandlerForSocialHandlers
  };
};

export default useBio;
