import React from "react";
import QRCode from "qrcode.react";
import { ShareQRCodeProps } from "./shareQRCode.types";
import StashrunLogoBeta from "./../../../assets/images/Stashrun-beta-logo-grey.png";
import VerifiedIcon from "../../../assets/images/verified.svg";
import { ImCross } from "react-icons/im";
import { convertHttpToHttps, generateCloudinaryImageUrl } from "../../../utils/common-function";

const ShareQRCode: React.FC<ShareQRCodeProps> = (props: ShareQRCodeProps) => (
  <div
    className={`flex justify-center items-center min-h-screen bg-black/40 fixed inset-0 z-50 transition-transform duration-300 backdrop-blur ${
      props.isOpen ? "translate-y-0" : "translate-y-full"
    }`}>
    <div className="container mx-auto grid grid-cols-12 gap-4 flex items-center">
      <div className="col-span-12 bg-soft-black text-center text-white rounded-3xl shadow-lg border-r-4 border-b-4 border-primary-l1 relative max-h-screen max-w-xs mx-auto overflow-auto">
        <div className="bg-modal-top-bg p-3 rounded-3xl">
          <div className="relative overflow-hidden">
            <div className="relative">
              <button className="absolute top-1 right-1 w-7 h-7 bg-primary-l1-dark/75 rounded-full flex justify-center items-center backdrop-blur-lg" onClick={props.onClose}>
                <ImCross className="text-base-white text-sm" />
              </button>
            </div>
            {props.coLinkUser ? (
              <div className="flex w-full h-[9.25rem]">
                <div
                  className="rounded-l-2xl w-1/2 h-full border-r-2 border-soft-black"
                  style={{
                    backgroundImage: `url(${generateCloudinaryImageUrl(convertHttpToHttps(props.stashUser.profilePhoto) || "", "248", "296")})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat"
                  }}></div>
                <div
                  className="rounded-r-2xl w-1/2 h-full border-l-2 border-soft-black"
                  style={{
                    backgroundImage: `url(${generateCloudinaryImageUrl(convertHttpToHttps(props.coLinkUser.profilePhoto) || "", "248", "296")})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat"
                  }}></div>
              </div>
            ) : (
              <div
                className="rounded-2xl w-full h-[9.25rem] rounded-bl-3xl"
                style={{
                  backgroundImage: `url(${generateCloudinaryImageUrl(convertHttpToHttps(props.stashUser.profilePhoto) || "", "504", "296")})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat"
                }}></div>
            )}
            <img
              src={generateCloudinaryImageUrl(convertHttpToHttps(props.charityLogo) || "", "120", "120")}
              alt="Logo"
              className={`absolute -bottom-2 ${
                props.coLinkUser ? "left-2/4 -translate-x-2/4" : "-left-2"
              } w-16 h-16 rounded-full border-8 border-modal-top-bg`}
              crossOrigin="anonymous"
            />
          </div>
          <div className="text-center pb-0">
            {props.coLinkUser ? (
              <>
                <h1 className="text-xl font-extrabold font-primary text-primary-l1 inline-block">
                  {props.stashUser.fullName}{" "}
                  {props.stashUser?.isPublicProfile && (
                    <img
                      src={VerifiedIcon}
                      alt={VerifiedIcon}
                      className="ml-0.5 w-3 h-3 inline-block align-middle relative -translate-y-1.5"
                    />
                  )}
                </h1>
                <p className="-mt-2">
                  <span className="text-primary-l1 font-primary text-xl font-extrabold">x</span>
                </p>
                <h1 className="text-xl font-extrabold font-primary text-primary-l1 inline-block">
                  {props.coLinkUser.fullName}{" "}
                  {props.coLinkUser?.isPublicProfile && (
                    <img
                      src={VerifiedIcon}
                      alt={VerifiedIcon}
                      className="ml-0.5 w-3 h-3 inline-block align-middle relative -translate-y-1.5"
                    />
                  )}
                </h1>
              </>
            ) : (
              <p className="relative -top-2 text-xl font-extrabold font-primary text-primary-l1 inline-block">
                {props.stashUser.fullName}{" "}
                {props.stashUser?.isPublicProfile && (
                  <img
                    src={VerifiedIcon}
                    alt={VerifiedIcon}
                    className="ml-0.5 w-3 h-3 inline-block align-middle relative -translate-y-1.5"
                  />
                )}
              </p>
            )}
            <p className="text-center font-secondary font-normal text-sm leading-tight-19.2">is stashing for</p>
            <div className="mb-1">
              <h3 className="text-center text-lg font-secondary font-bold leading-tight-21.6 mt-2">{props.charityName}</h3>
            </div>
            <div className="flex justify-center items-center mt-[18px]">
              {props.shareUrl && <QRCode value={props.shareUrl} size={120} bgColor="transparent" fgColor="#ffffff" />}
            </div>
            <p className="text-base-white text-xs font-normal font-secondary text-white mt-[16px]">
              Share this QR code so friends can play
            </p>
          </div>
        </div>

        <div className="p-4 flex justify-center items-center">
          <img src={StashrunLogoBeta} alt={StashrunLogoBeta} className="h-3" />
        </div>
      </div>
    </div>
  </div>
);

export default ShareQRCode;
