import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useMutation } from "@apollo/client";
import { SYNC_SUPABASE_USER } from "../../../../queries";
import { getLocalStorage, getSupabaseSession, setCookie, setLocalStorage } from "../../../../utils/common-function";
import { routes } from "../../../../routes/routes.constant";
import { ILocationState } from "./types";
import useUser from "../../../../services/hooks/useUser";
import { useAuth } from "../../../../store/contextAPI/authContext";

const useRedirectCallback = () => {
  const history = useHistory();
  const location = useLocation<ILocationState>();
  const [error, setError] = useState("");
  const [syncSupabaseUser] = useMutation(SYNC_SUPABASE_USER);
  const userId = getLocalStorage("userId");
  const { userData } = useUser(userId);
  const { updateUserProfile } = useUser(userId);

  const [isLoading, setLoading] = useState(true);
  const { setNameContext, setPasswordContext } = useAuth();

  const getUserDetails = useCallback(async () => {
    const session = await getSupabaseSession();
    if (session) {
      setCookie("token", session.access_token);
      const { email, name } = session.user.user_metadata;
      syncUser();
      if (name) {
        const [firstName, lastName = ""] = name.trim().split(" ");
        setPasswordContext("");
        setNameContext(firstName, lastName);
      }
      setLocalStorage("email", email);
      setLoading(false);
    } else {
      history.push({ pathname: routes.onboarding, state: { from: location.state?.from } });
    }
  }, [history]);

  const syncUser = useCallback(async () => {
    const result = await syncSupabaseUser({
      variables: { input: {} }
    });
    const userId = result.data.syncSupabaseUser.user.id;
    setLocalStorage("userId", userId);
  }, []);

  const onUpdateSuccess = useCallback(() => {
    setError("");
  }, [history, location.state]);

  const onUpdateError = useCallback(() => {
    setError("An error occurred while updating your profile. Please try again.");
  }, []);

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    if (userData) {
      const { finishedOnBoarding, fullName, birthdate, profilePhoto } = userData;
      if (finishedOnBoarding) {
        history.push({ pathname: location.state?.from ?? "/", state: location.state });
      } else {
        if (!fullName) {
          history.push({ pathname: routes.userName, state: location.state });
        } else if (!birthdate) {
          history.push({ pathname: routes.userDob, state: location.state });
        } else if (!profilePhoto) {
          history.push({ pathname: routes.addPhoto, state: location.state });
        } else {
          updateUserProfile({ finishedOnBoarding: true }, onUpdateSuccess, onUpdateError);
          history.push({ pathname: location.state?.from ?? "/", state: location.state });
        }
      }
    }
  }, [userData]);

  return {
    error,
    isLoading
  };
};

export default useRedirectCallback;
