import React from "react";
import { ImpactBadgeProps } from "./types";
import FlashIcon from "../../svgImages/flashIcon";
import { getStyles } from "../../../utils/common-function";

const ImpactBadge: React.FC<ImpactBadgeProps> = ({ impact, isImpactIconRequired = false, setIsUserProfileModalOpen, impactScoreLevel }) => {
  const openProfileModal = () => {
    setIsUserProfileModalOpen && setIsUserProfileModalOpen(true);
  };

  const styles = getStyles(impactScoreLevel);

  return (
    <div
      className={`inline-flex items-center rounded-full text-white font-semibold relative badges mx-2 mr-0 mb-0 ${isImpactIconRequired ? `with-check absolute bottom-0 left-2/4 -translate-x-2/4 w-fit flex items-center text-base-white text-sm rounded-2xl border-b-4 border-r-4 border-black/60` : "without-check"}`}
      style={{ background: styles.levelColor }}
      onClick={() => openProfileModal()}>
      <div className="bg-gradient-to-r from-black/50 to-black/80 space-x-2 px-4 py-1 pb-0.5 rounded-xl px-3 py-1 ps-8">
        {isImpactIconRequired && (
          <span className="absolute left-0 -top-0.5 text-3xl mr-1 text-base-white">
            <FlashIcon color={styles.levelColor} className="h-10 relative left-2 -top-1.5" />
          </span>
        )}
        <span className="text-xs !ml-6px">{impact}</span>
      </div>
    </div>
  );
};

export default ImpactBadge;
