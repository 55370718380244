import { useCallback, useEffect, useRef } from "react";
import useSound from "use-sound";
import CoinCollect1 from "../../../assets/sounds/coin-collect1.mp3";
import CoinCollect2 from "../../../assets/sounds/coin-collect2.mp3";
import CoinCollect3 from "../../../assets/sounds/coin-collect3.mp3";
import CoinCollect4 from "../../../assets/sounds/coin-collect4.mp3";
import Stashy1 from "../../../assets/sounds/Stashy1.mp3";
import Stashy2 from "../../../assets/sounds/Stashy2.mp3";
import Stashy3 from "../../../assets/sounds/Stashy3.mp3";
import Stashy4 from "../../../assets/sounds/Stashy4.mp3";
import Stashy5 from "../../../assets/sounds/Stashy5.mp3";
import BackGroundMusic from "../../../assets/sounds/bubble-pop-background.mp3";

const useGameSounds = () => {
  const gameBeginMusic = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    gameBeginMusic.current = new Audio(BackGroundMusic);
    gameBeginMusic.current.load();
  }, []);

  const moneySounds = [CoinCollect1, CoinCollect2, CoinCollect3, Stashy1, Stashy2, Stashy3, Stashy4, Stashy5].map((sound) =>
    useSound(sound, { volume: 1 })
  );

  const [playLastBubbleSound] = useSound(CoinCollect4, { volume: 1 });

  const playMoneySound = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * moneySounds.length);
    const playRandomMoneySound = moneySounds[randomIndex][0];
    playRandomMoneySound();
  }, [moneySounds]);

  const startGameMusic = useCallback(() => {
    if (gameBeginMusic.current) {
      gameBeginMusic.current.currentTime = 0;
      gameBeginMusic.current.play();
    }
  }, []);

  const stopGameMusic = useCallback(() => {
    gameBeginMusic.current?.pause();
  }, []);

  return {
    startGameMusic,
    playMoneySound,
    playLastBubbleSound,
    stopGameMusic
  };
};
export default useGameSounds;
