import Button from "../../../components/common/button";
import { buttonTitle, heading, modalTitles, placeholderText, labels, onboardingText, imageAltText, names } from "./constant";
import useBio from "./hooks/useBio";
import OnboardingWrapper from "../../../containers/onboardingWrapper";
import { routes } from "../../../routes/routes.constant";
import FlashGreen from "./../../../assets/images/flash-green.svg";
import Add from "./../../../assets/images/Add-Grey.svg";
import Modal from "../../../components/common/modal/modal";
import TextArea from "../../../components/common/text-area/text-area";
import InputField from "../../../components/common/input/input";
import Loader from "../../../components/common/loader/loader";
import InstaGramIcon from "../../../components/svgImages/instaGram";
import TikTokIcon from "../../../components/svgImages/tiktok";
import YouTubeIcon from "../../../components/svgImages/youTube";

const Bio = () => {
  const {
    user,
    isLoading,
    userBioContext,
    userNameContext,
    userBio,
    instagramId,
    tiktokId,
    youtube,
    onChangeInstagramId,
    onChangeTiktokId,
    onContinueHandler,
    onChangeYoutube,
    onOpenCloseBioModal,
    onChangeBio,
    onOpenCloseSocialMediaModal,
    isBioModalOpen,
    isSocialHandlersModalOpen,
    onDoneHandlerForBio,
    onDoneHandlerForSocialHandlers,
    instagramIdAfterDone,
    tiktokIdAfterDone,
    youtubeAfterDone
  } = useBio();

  if (isLoading)
    return (
      <div className="h-screen w-screen flex justify-center items-center  bg-soft-black">
        <Loader />
      </div>
    );

  return (
    <>
      <OnboardingWrapper heading={heading.looksGood} isTabVisible={true} tabId={3} totalTab={4} backButtonPath={routes.addPhoto}>
        <p className="mt-0 text-base-white text-center text-[14px] font-secondary px-6 font-normal xsh:leading-4 leading-tight-18.2">{onboardingText.description}</p>

        <div className="fixed w-full bg-soft-black left-0 bottom-0  z-10">
          <div className="bg-gradient-to-t from-modal-bottom-bg to-modal-top-bg flex flex-col rounded-t-2xl border-b-2 border-b-modal-top-bg items-center p-4 pt-0 xsh:pb-0" >
            <div className="relative -top-[52px] xsh:-top-[40px] rounded-full bg-primary-l1 w-164 h-168">
              <div
                className="absolute left-1/2 -translate-x-1/2 bottom-0.5 w-[148px] h-[148px] rounded-full"
                style={{
                  backgroundImage: `url(${URL.createObjectURL(user.photo)})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat"
                }}></div>
              {user.bio.length !== 0 && (
                <div className="absolute -bottom-2 left-2/4 -translate-x-2/4 w-fit flex items-center text-base-white text-sm bg-gradient-to-t from-button-bottom-bg to-button-top-bg px-3 py-1 ps-10 backdrop-blur-10 rounded-2xl border-b-2.44 border-r-2.44 border-black/25">
                  <span className="absolute left-0 top-0.5 text-3xl mr-1 text-base-white">
                    <img src={FlashGreen} alt={imageAltText.flashGreen} className="h-10 relative left-2 -top-1.5" />
                  </span>
                  <span>{user.impactScore}</span>
                </div>
              )}
            </div>

            <Modal
              isOpen={isBioModalOpen}
              title={modalTitles.addBio}
              onButtonClick={onDoneHandlerForBio}
              buttonTitle={buttonTitle.done}
              onClickClose={onOpenCloseBioModal}>
              <TextArea
                label={labels.bio}
                name={names.bio}
                placeholder={placeholderText.bio}
                value={userBio}
                onChange={onChangeBio}
                maxLength={140}
                currentLength={userBio.length}
                rows={4}
              />
            </Modal>

            <Modal
              isOpen={isSocialHandlersModalOpen}
              title={modalTitles.addSocialHandles}
              onButtonClick={onDoneHandlerForSocialHandlers}
              buttonTitle={buttonTitle.done}
              onClickClose={onOpenCloseSocialMediaModal}>
              <InputField
                onChange={onChangeInstagramId}
                label={labels.instagram}
                name={names.instagram}
                placeholder={placeholderText.instagram}
                value={instagramId}
                showClearIcon={instagramId.length !== 0}
              />
              <InputField
                onChange={onChangeTiktokId}
                label={labels.tiktok}
                name={names.tiktok}
                placeholder={placeholderText.tiktok}
                value={tiktokId}
                showClearIcon={tiktokId.length !== 0}
              />
              <InputField
                onChange={onChangeYoutube}
                label={labels.youtube}
                name={names.youtube}
                placeholder={placeholderText.youtube}
                value={youtube}
                showClearIcon={youtube.length !== 0}
              />
            </Modal>

            <div className="w-full -mt-8">
              <h3 className="text-primary-l1 text-2xl text-center font-primary ">{userNameContext}</h3>
              <p onClick={onOpenCloseBioModal} className="mt-1 text-base-white/90 text-sm font-normal text-center font-secondary break-all leading-tight-18.2">
                {userBioContext}
              </p>
              {userBioContext.length === 0 && (
                <a
                  className="mt-3 xsh:mt-2 block border border-base-white/70 bg-base-white/5 text-sm font-normal text-base-white/80 text-center px-4 py-3 h-11 flex items-center justify-center font-secondary rounded-lg "
                  onClick={onOpenCloseBioModal}>
                  {onboardingText.addBio}
                </a>
              )}
              {instagramIdAfterDone.length === 0 && (
                <a
                  className="mt-3 xsh:mt-2 block border border-base-white/70 bg-base-white/5 text-sm font-normal text-base-white/80 text-center px-4 py-3 h-11 flex items-center justify-center font-secondary rounded-lg"
                  onClick={onOpenCloseSocialMediaModal}>
                  {onboardingText.addSocials}
                </a>
              )}

              <div className="flex justify-center mt-4">
                {instagramIdAfterDone.length !== 0 && (
                  <a href={`https://www.instagram.com/${instagramIdAfterDone}/`} target="_blank" rel="noopener noreferrer">
                    <InstaGramIcon color="#D7FF3E" width="40" height="40" className="mx-2" />
                  </a>
                )}
                {tiktokIdAfterDone.length !== 0 && (
                  <a href={`https://www.tiktok.com/${tiktokIdAfterDone}/`} target="_blank" rel="noopener noreferrer">
                    <TikTokIcon color="#D7FF3E" width="40" height="40" className="mx-2" />
                  </a>
                )}
                {youtubeAfterDone.length !== 0 && (
                  <a href={`https://www.youtube.com/${youtubeAfterDone}/`} target="_blank" rel="noopener noreferrer">
                    <YouTubeIcon color="#D7FF3E" width="40" height="40" className="mx-2" />
                  </a>
                )}
                {instagramIdAfterDone.length || tiktokIdAfterDone.length || youtubeAfterDone.length ? (
                  <img src={Add} alt={imageAltText.add} className="w-10 mx-2" onClick={onOpenCloseSocialMediaModal} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="p-4 pb-5">
            <Button title={buttonTitle.finishProfile} isDisabled={false} onClick={onContinueHandler} />
          </div>
        </div>
      </OnboardingWrapper>
    </>
  );
};

export default Bio;
