export class GestureHandler {
  private targetElement: HTMLElement | null;

  constructor(targetElement: HTMLElement | null) {
    this.targetElement = targetElement;
  }

  private handlePreventSwipeRightGesture = (event: TouchEvent): void => {
    const touch = event.touches[0];
    // Check if the touch point is near the edge
    if (touch.pageX > 70 && touch.pageX < window.innerWidth - 50) return;
    // Prevent the default action if near the edge
    event.preventDefault();
  };

  private handlePreventPinchZoom(event: TouchEvent): void {
    if (event.touches.length > 1) {
      event.preventDefault();
    }
  }

  public attachPreventSwipeRightListeners(): void {
    this.targetElement?.addEventListener("touchstart", this.handlePreventSwipeRightGesture);
  }

  public detachPreventSwipeRightListeners(): void {
    this.targetElement?.removeEventListener("touchstart", this.handlePreventSwipeRightGesture);
  }

  public attachPreventPinchZoomListeners(): void {
    this.targetElement?.addEventListener("touchstart", this.handlePreventPinchZoom, { passive: false });
    this.targetElement?.addEventListener("touchmove", this.handlePreventPinchZoom, { passive: false });
  }

  public detachPreventPinchZoom(): void {
    this.targetElement?.removeEventListener("touchstart", this.handlePreventPinchZoom);
    this.targetElement?.removeEventListener("touchmove", this.handlePreventPinchZoom);
  }

  public getCleanupFunction(): () => void {
    return () => {
      this.detachPreventSwipeRightListeners();
      this.detachPreventPinchZoom();
    };
  }
}
