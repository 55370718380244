import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { routes } from "../../../routes/routes.constant";
import { getCookie, getLocalStorage } from "../../../utils/common-function";
import useUser from "../../../modules/campaign/hooks/useUser";
import mixpanel from "../../../services/mixpanelService";

export const useHeader = () => {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState<boolean>(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(getLocalStorage("userId"));
  const [profileImage, setProfileImage] = useState("");
  const history = useHistory();
  const location = useLocation();
  const { userData, fetchUser } = useUser();

  // Sync userId state with localStorage continuously
  useEffect(() => {
    const delayTime: number = !userId ? 0 : 500;
    const syncUserId = () => {
      const storedUserId = getLocalStorage("userId");
      if (!userId) {
        setProfileImage("");
      }
      if (storedUserId !== userId) {
        setUserId(storedUserId);
      }
    };

    const intervalId = setInterval(syncUserId, delayTime);

    return () => clearInterval(intervalId);
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchUser({ variables: { userId } });
    }
  }, [fetchUser, userId]);

  useEffect(() => {
    if (userData?.user?.profilePhoto) {
      setProfileImage(userData.user.profilePhoto);
    }
  }, [userData?.user?.profilePhoto]);

  const toggleProfileMenu = useCallback(() => {
    setIsProfileMenuOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const onClickStashrunLogo = useCallback(() => {
    mixpanel.track("header-logo-clicked");
    if (!isUserOnAuthRoute) {
      //TODO: uncomment it after test all the scenatios
      //history.replace({ ...location, state: {} });
      history.push("/");
    }
  }, []);

  const onClickUserIcon = useCallback(() => {
    mixpanel.track("header-user-profile-clicked");
    const token = getCookie("token");
    if (!token) {
      history.push(routes.onboarding, { state: location.state, from: location.pathname });
    } else {
      toggleProfileMenu();
    }
  }, [history, toggleProfileMenu]);

  const isUserOnAuthRoute = useMemo((): boolean => {
    const currentRoute: string = history.location.pathname;
    return currentRoute.includes("onboarding") || currentRoute.includes("login") || currentRoute.includes("forgot-password");
  }, [history.location.pathname]);

  const toggleHamburgerMenu = useCallback(() => {
    mixpanel.track("header-hamburger-clicked");
    setIsHamburgerOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return {
    isHamburgerOpen,
    onClickStashrunLogo,
    isProfileMenuOpen,
    profileImage,
    onClickUserIcon,
    isUserOnAuthRoute,
    toggleHamburgerMenu,
    toggleProfileMenu,
    closeHamburgerMenu: () => setIsHamburgerOpen(false),
    closeProfileMenu: () => setIsProfileMenuOpen(false)
  };
};
