export const Constants = {
  sizeMap: {
    xs: "7",
    s: "12",
    m: "20",
    l: "32",
    xl: "40"
  }
};

export const inputType = {
  text: "text",
  password: "password",
  file: "file"
};

export const emptyBubble = [16, 17, 18, 23, 24, 25, 30, 31, 32];
export const gameDefaultAmounts = [0.01, 0.05, 0.1, 0.2, 0.25, 0.5];
export const oneDollar = "1.00";
export const gameType = {
  bubblePop: "bubble_pop"
};

export const timeout = {
  halfSecond: 500,
  eightHundred: 800,
  twoThousand: 2000,
  twoThousandSixHundred: 2600,
  threeThousand: 3000,
  fourThousand: 4000,
  fiveThousand: 5000,  
  fiveThousandFiveHundred: 5500,
  sixThousand: 6000,
  
};

export const sevenSecondTimer = 14.29;

//Prod
// export const envVariables = {
//   apiEndPoint: "https://api.stashrun.com/graphql",
//   domainUrl: "https://app.stashrun.com",
//   supabaseUrl: "https://login.stashrun.com",
//   supabaseAnonKey:
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InB3cWhpcHZ1d2xqdWVqdmJrZ2VxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQ4Mjc2NTYsImV4cCI6MjA0MDQwMzY1Nn0.yfli5Qu9Qh-HFOIwbAx4JZsI_Vpy8rEjzYMdNhzpz7g"
// };
// export const MIXPANEL_TOKEN = "08a9b1277e0cbd82ce9b76080a774ed9";

//Staging Environment variable
export const envVariables = {
  apiEndPoint: "https://api-staging.stashrun.com/graphql",
  domainUrl: "https://staging.stashrun.com",
  supabaseUrl: "https://login.staging.stashrun.com",
  supabaseAnonKey:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Inh2d2NmZ3VveXNqbWtra2V6dm1vIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcyMzcyMDk3OCwiZXhwIjoyMDM5Mjk2OTc4fQ.aRUFyfk4ush9aywNERYawnibnXmAs-HBLo9JVt2F7b4"
};
//For testing to avoid unwanted data on production
export const MIXPANEL_TOKEN = "08a9b1277e0cbd82ce9b760854774ed910";

