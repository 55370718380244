import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { FaHeart } from "react-icons/fa6";
import Signature from "../../assets/images/signature.svg";
import MarqueeComponent from "../../components/common/marquee/marqueeComponent";
import { marqueeTextIteration, staticStashlinkId } from "../../utils/constants";
import Button from "../../components/common/button";
import { routes } from "../../routes/routes.constant";
import useAuthStatus from "../campaign/hooks/useAuthStatus";
import { generateCloudinaryImageUrl, setLocalStorage } from "../../utils/common-function";
import ImpactScoreModal from "../../components/impactScoreModal/impactScoreModal";
import { useImpactScoreModal } from "../../services/hooks/useImpactScoreModal";
import useStashLinkHooks from "../campaign/hooks/useStashLink";
import Loader from "../../components/common/loader/loader";
import useFollowingEntity from "../../services/hooks/useFollowingEntity";
import useFollowEntity from "../../services/hooks/useFollowEntity";
import useColinkExist from "../../services/hooks/useColinkExist";
import useStashlinkExist from "../../services/hooks/useStashlinkExist";
import FollowingCheck from "../../assets/images/following-check.svg";
import mixpanel from "../../services/mixpanelService";

const StaticHomePage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const state = location.state as { userName?: string; followerProfileImage?: string; param?: any };
  const isFollow = state?.param?.isFollow;
  const { isUserLoggedIn, currentUser } = useAuthStatus();
  const { isImpactScoreModalOpen, setIsImpactScoreModalOpen, impactScoreLevel } = useImpactScoreModal(currentUser?.user);
  const [changeStatusFollowing, setChangeStatusFollowing] = useState<boolean>(false);
  const { getStashLink, stashlink, stashlinkLoading } = useStashLinkHooks();
  const user = stashlink?.user;
  const campaign = stashlink?.campaign;
  const { isUserFollowing, refetch } = useFollowingEntity(currentUser?.user.id || "", user?.id || "");
  const { followCelebrity } = useFollowEntity(user?.id || "", refetch);
  const { colinkExistLoading, colinkExistData } = useColinkExist(stashlink?.id || "");
  const { stashlinkExistLoading, stashlinkExistData } = useStashlinkExist(campaign?.id || "");
  const [showFollowButton, setShowFollowButton] = useState<boolean>(false);

  setLocalStorage("stashlink", stashlink);

  useEffect(() => {
    getStashLink({
      variables: {
        stashlinkId: state?.param?.stashLinkId || staticStashlinkId
      }
    });
  }, [getStashLink]);

  useEffect(() => {
    if (isFollow) {
      updateCelebrityFollowStatus();
    }
  }, [isFollow]);

  const updateCelebrityFollowStatus = async () => {
    await followCelebrity(true);
    setChangeStatusFollowing(true);
  };
  useEffect(() => {
    mixpanel.track("home-page-viewed");
  }, []);

  useEffect(() => {
    if (colinkExistData || stashlinkExistData) {
      setShowFollowButton(true);
    }
  }, [colinkExistData, stashlinkExistData]);

  const handelFollowCelebrity = async () => {
    mixpanel.track("home-page-follow-clicked");
    if (isUserLoggedIn) {
      await followCelebrity(true);
      setChangeStatusFollowing(true);
    } else {
      history.push({
        pathname: routes.onboarding,
        state: { from: "/", userName: user?.fullName, followerProfileImage: user?.profilePhoto }
      });
    }
  };

  if (stashlinkLoading || colinkExistLoading || stashlinkExistLoading)
    return (
      <div className="h-screen w-screen flex justify-center items-center  bg-soft-black">
        <Loader />
      </div>
    );

  return (
    <div className="flex justify-center bg-soft-black">
      <div className="w-full text-center text-white">
        <div className="bg-black">
          <MarqueeComponent text="you play = sponsors pay" iterationsPerScreen={marqueeTextIteration} separatorIcon={FaHeart} />
        </div>
        <div className="p-4 pt-17">
          <div className="bg-gradient-to-t from-modal-bottom-bg to-modal-top-bg rounded-t-28 rounded-b-20 p-4 mx-auto border border-b-8 border-black">
            <div className="relative overflow-hidden">
              <img
                src={generateCloudinaryImageUrl(user?.profilePhoto || "", "622", "365")}
                alt={user?.fullName}
                className="rounded-xl rounded-bl-38 w-full h-182 object-cover"
              />
              <img
                src={generateCloudinaryImageUrl(campaign?.charity?.imageUrl || "", "148", "148")}
                alt="Logo"
                className="absolute -bottom-2 -left-2 rounded-full w-c-74 h-c-74 border-8 border-[#30390E] object-cover"
              />
            </div>
            <div className="mt-4 text-left">
              <p className="mb-4 font-secondary text-sm text-base-white font-medium leading-tight-16.8">
                Thanks for being a part of {user?.fullName} community and be the first to know when the next play to fund campaign goes
                live.
              </p>
              <p className="mb-4 font-secondary text-sm text-base-white font-medium leading-tight-16.8">
                Stashrun lets you play games to fund charities, together with your friends and favorite celebs.
              </p>
              <a className="text-[#419CFF] underline font-secondary text-base">Sign up to vote</a>
            </div>
            <div className="relative mt-4 flex justify-between items-center">
              <p className="font-secondary text-base text-left text-base-white">XOXO, {user?.fullName}</p>
              <img src={Signature} alt="Signature" className="absolute right-4 -bottom-1 w-c-70 h-auto" />
            </div>
            <div className="w-full gap-[8.01px] mt-4">
              {campaign?.currentAmount != campaign?.goalAmount && !showFollowButton && !colinkExistData ? (
                <Button
                  onClick={() =>
                    isUserLoggedIn
                      ? history.push({ pathname: routes.callback, state: { from: routes.colink } })
                      : history.push({
                          pathname: routes.onboarding,
                          state: { from: routes.colink, userName: user?.fullName, followerProfileImage: user?.profilePhoto }
                        })
                  }
                  isDisabled={false}
                  title="Team up with me"
                />
              ) : isUserLoggedIn && (isUserFollowing || changeStatusFollowing) ? (
                <div className="w-full mt-2 p-4 px-0">
                  <button
                    onClick={() => mixpanel.track("home-page-followed-clicked")}
                    className="w-full px-4 py-3 text-lg font-primary tracking-wider font-bold rounded-lg text-grey bg-grey-bg border border-grey flex align-center justify-center">
                    Following{" "}
                    <span className="ml-1">
                      <img src={FollowingCheck} alt="Following Check"></img>
                    </span>
                  </button>
                </div>
              ) : (
                <Button onClick={handelFollowCelebrity} isDisabled={false} title={`Follow ${user?.fullName.split(" ")[0]}`} />
              )}
            </div>
          </div>
          {campaign?.currentAmount != campaign?.goalAmount && (!isUserLoggedIn || !stashlinkExistData) && !showFollowButton && (
            <div
              className="relative overflow-hidden mt-8 bg-cover bg-center rounded-3xl border border-b-8 border-black"
              style={{
                backgroundImage: `url(${generateCloudinaryImageUrl(campaign?.imageUrl || "", "686", "542")})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
              }}>
              <div className="bg-black/40 p-4">
                <div className="flex flex-col items-center">
                  <img
                    src={generateCloudinaryImageUrl(campaign?.charity?.imageUrl || "", "176", "176")}
                    alt="Logo"
                    className="w-24 h-24 bg-soft-black p-1.5 rounded-full object-cover"
                  />
                  <p className="text-base-white text-center text-sm mt-4 font-medium leading-tight-16.8 inter-I">
                    {campaign?.cause?.heroTagline}
                  </p>
                </div>
                <div className="text-white mt-4">
                  <Button
                    isDisabled={false}
                    onClick={() => {
                      mixpanel.track("home-page-create-stashlink-clicked");
                      isUserLoggedIn
                        ? history.push({ pathname: routes.callback, state: { from: routes.colink, param: { isCreateStashLink: true } } })
                        : history.push({
                            pathname: routes.onboarding,
                            state: {
                              from: routes.colink,
                              param: { isCreateStashLink: true }
                            }
                          });
                    }}
                    title="Create a stashlink"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {isImpactScoreModalOpen && (
        <ImpactScoreModal
          user={currentUser?.user}
          impactScoreLevel={impactScoreLevel}
          setIsImpactScoreModalOpen={setIsImpactScoreModalOpen}
        />
      )}
    </div>
  );
};

export default StaticHomePage;
