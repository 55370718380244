import { ISignupCredentials } from "./../../../../store/contextAPI/types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { routes } from "../../../../routes/routes.constant";
import { useAuth } from "../../../../store/contextAPI/authContext";
import { IValue } from "../../Onboarding/hooks/types";
import { getCookie, getLocalStorage } from "../../../../utils/common-function";
import mixpanel from "../../../../services/mixpanelService";

const useEnterPassword = () => {
  const history = useHistory();
  const { user, setPasswordContext, handleLogin: handleLoginViaSupabase } = useAuth();
  const [password, setPassword] = useState<IValue>({
    value: "",
    error: ""
  });

  const isContinueDisable = useMemo((): boolean => password.value.length === 0, [password.value.length]);

  useEffect(() => {
    mixpanel.track("login-password-viewed");
  }, []);

  const onChangePassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    value = value.replace(/\s+/g, "");
    if (value.length > 20) {
      value = value.slice(0, 20);
    }
    setPassword({ value: value, error: "" });
  }, []);

  const onClickContinueHandler = useCallback(async () => {
    setPasswordContext(password.value);
    const finalEmail: string = user.email ? user.email : getLocalStorage("email");
    const credentials: ISignupCredentials = {
      email: finalEmail,
      password: password.value
    };
    await handleLoginViaSupabase(credentials);
    const token = getCookie("token");
    if (!token) {
      setPassword({ ...password, error: "Invalid login credentials" });
    }
  }, [password.value, setPasswordContext]);

  const handleForgotPassword = useCallback(() => {
    history.push(routes.forgotPassword);
  }, [history]);

  return {
    password,
    onClickContinueHandler,
    onChangePassword,
    isContinueDisable,
    handleForgotPassword
  };
};

export default useEnterPassword;
