import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShareButton from "../common/shareButton/shareButton";
import ShareQRCode from "../common/shareQRCode/shareQRCode";
import { ShareModalProps } from "./shareModal.types";
import { sendSMS } from "../../utils/share";
import StashrunLogoBeta from "./../../assets/images/Stashrun-beta-logo-grey.png";
import { formatCurrencyToUsd } from "../../utils/format";
import useShareModal from "./hooks/useShareModal";
import VerifiedIcon from "./../../assets/images/verified.svg";
import Check from "./../../assets/images/Check.svg";
import { convertHttpToHttps, convertToPercentage, generateCloudinaryImageUrl } from "../../utils/common-function";
import { RxCross2 } from "react-icons/rx";
import mixpanel from "../../services/mixpanelService";

const ShareComponent: React.FC<ShareModalProps> = (props: ShareModalProps) => {
  const { imageRef } = useShareModal(props);
  const [showQr, setShowQr] = useState<boolean>(false);
  const [isCopyLinkDisabled, setIsCopyLinkDisabled] = useState<boolean>(false);
  const percentageCompleted = convertToPercentage(props?.currentAmount, props?.goalAmount);

  const copyLink = (shareUrl: string) => {
    setIsCopyLinkDisabled(true);
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        toast.success(
          <div className="w-full flex flex-col items-center">
            <img src={Check} alt="Success" className="w-22 h-22" />
            <span className="font-proxima-nova text-[#B0C1D3] text-base font-semibold leading-5 text-center">Copied</span>
          </div>,
          {
            icon: false,
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            closeButton: false,
            onClose: () => setIsCopyLinkDisabled(false)
          }
        );
      })
      .catch((error) => {
        console.error("Failed to copy: ", error);
        setIsCopyLinkDisabled(false);
      });
  };

  return (
    <div>
      <div
        className={`fixed inset-0 z-50 min-h-screen flex flex-col justify-between bg-soft-black text-white rounded-t-2xl transition-transform duration-300 ${
          props.isOpen ? "translate-y-0" : "translate-y-full"
        }`}>
        <header className="text-center p-4 flex flex-col">
          <button onClick={props.onClose} className="absolute right-4 text-grey text-lg hover:text-gray-100 font-semibold z-10">
            <RxCross2 />
          </button>
        </header>
        <div>
          <main className="flex flex-col items-center justify-end p-12 pt-4 pb-0  relative -bottom-0.5">
            <p className="text-xl font-bold font-primary text-base-white">Share to raise more</p>
            <p className="text-sm text-base-white font-secondary mt-3 mb-4">Long press to save and share to IG👇</p>
            <div className="overflow-hidden border-2 border-r-8 border-black border-b-0 rounded-t-3xl relative w-full">
              <div ref={imageRef} className="flex items-end w-full">
                <div className="share-modal-bg h-full px-c-60 pt-16 pb-32 w-full">
                  <div className="bg-soft-black rounded-2xl h-full border-r-2.44 border-b-2.44 border-primary-l1 overflow-hidden">
                    <div className="bg-black/30">
                      <div className="bg-soft-black rounded-[12px] p-2 pb-1">
                        
                        <div className="relative overflow-hidden">
                          {props.coLinkUser ? (
                            <div className="flex w-full">
                              <div className="rounded-l-[8px] w-1/2 h-28 border-r-2 border-soft-black flex items-center justify-center">
                                <img
                                  src={generateCloudinaryImageUrl(convertHttpToHttps(props.stashUser.profilePhoto) || "", "185", "220")}
                                  alt="Stash User"
                                  className="w-full h-full object-cover rounded-l-[8px]"
                                />
                              </div>
                              <div className="rounded-r-[8px] w-1/2 h-28 border-l-2 border-soft-black flex items-center justify-center">
                                <img
                                  src={generateCloudinaryImageUrl(convertHttpToHttps(props.coLinkUser.profilePhoto) || "", "185", "220")}
                                  alt="Co-link User"
                                  className="w-full h-full object-cover rounded-r-[8px]"
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="rounded-[8px] w-full h-24 flex items-center justify-center">
                              <img
                                src={generateCloudinaryImageUrl(convertHttpToHttps(props.stashUser.profilePhoto) || "", "750", "496")}
                                alt="Stash User"
                                className="w-full h-full object-cover rounded-[8px] c-object-fit"
                              />
                            </div>
                          )}
                          <img
                            src={
                              props.coLinkUser
                                ? generateCloudinaryImageUrl(convertHttpToHttps(props.charityLogo) || "", "89", "89")
                                : generateCloudinaryImageUrl(convertHttpToHttps(props.charityLogo) || "", "120", "120")
                            }
                            alt="Logo"
                            className={`absolute -bottom-1 ${
                              props.coLinkUser ? "left-2/4 -translate-x-2/4" : "-left-1"
                            } w-11 h-11 rounded-full border-4 border-soft-black`}
                            crossOrigin="anonymous"
                          />
                        </div>

                        <div className="text-center">
                          {props.coLinkUser ? (
                            <>
                              <p className="text-14.97 font-extrabold font-primary text-primary-l1 inline-block m-0">
                                <span className="relative -top-1.5">{props.stashUser.fullName} </span>
                                {props.stashUser?.isPublicProfile && (
                                  <img
                                    src={VerifiedIcon}
                                    alt={VerifiedIcon}
                                    className="ml-0.5 w-8.98 h-8.98 inline-block align-middle relative -top-0.5"
                                  />
                                )}
                              </p>
                              <p className="text-primary-l1 text-xs font-primary font-extrabold m-0 -mt-1.5 -mb-1.5 relative -top-1.5">x</p>
                              <p className="text-14.97 font-extrabold font-primary text-primary-l1 inline-block m-0">
                                <span className="relative -top-1.5">{props.coLinkUser.fullName} </span>
                                {props.coLinkUser?.isPublicProfile && (
                                  <img
                                    src={VerifiedIcon}
                                    alt={VerifiedIcon}
                                    className="ml-0.5 w-8.98 h-8.98 inline-block align-middle relative -top-0.5"
                                  />
                                )}
                              </p>
                            </>
                          ) : (
                            <p className="text-14.97 font-extrabold font-primary text-primary-l1 inline-block">
                              <span className="relative -top-2">{props.stashUser.fullName} </span>
                              {props.stashUser?.isPublicProfile && (
                                <img
                                  src={VerifiedIcon}
                                  alt={VerifiedIcon}
                                  className="ml-0.5 w-8.98 h-8.98 inline-block align-middle relative -top-0.5"
                                />
                              )}
                            </p>
                          )}
                          <p className="text-10.48 text-base-white font-secondary font-normal relative -top-1.5">is stashing for</p>
                          <p className="text-sm text-base-white font-bold font-secondary relative -top-1.5">{props.charityName}</p>
                          <div className="mt-2 text-white">
                            <div className="relative w-full h-2 bg-grey-bg rounded-xl overflow-hidden mb-1 top-1">
                              <div className="absolute h-full bg-primary-l1" style={{ width: `${percentageCompleted}%` }}></div>
                            </div>
                            <p className="-mt-0.5 relative -top-1.5">
                              <span className="text-[0.686rem] font-bold font-secondary leading-[0.823rem]">
                                {formatCurrencyToUsd(props.currentAmount)}{" "}
                              </span>
                              <span className="text-8.98 text-neutral-50 font-secondary font-normal leading-3">
                                raised of {formatCurrencyToUsd(props.goalAmount)}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="px-2 py-2 flex justify-center items-center">
                        <img src={StashrunLogoBeta} alt={StashrunLogoBeta} className="h-2" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="absolute left-2/4 -translate-x-2/4 bottom-12 w-[calc(100vh+10px)] bg-primary-l1 text-black text-center text-xs font-extrabold tracking-wide font-primary py-2 -rotate-3">
                  <span className="relative -top-2">Join us... Play to fund!</span>
                </div>
              </div>
            </div>
          </main>
          <footer className="w-full bg-primary-l1 border-modal-top-bg z-10">
            <div className="bg-black/90 grid grid-cols-3 gap-4 w-full py-4 px-8">
              <ShareButton buttonName="SMS" buttonOnClick={() => sendSMS(props.shareUrl || "")} />
              <ShareButton buttonName="Copy Link" buttonOnClick={() => copyLink(props?.shareUrl || "")} disabled={isCopyLinkDisabled} />
              <ShareButton
                buttonName="QR Code"
                buttonOnClick={() => {
                  setShowQr(true);
                  mixpanel.track("qr-button-clicked");
                }}
              />
            </div>
          </footer>
        </div>

        {showQr && (
          <>
            {props.coLinkUser ? (
              <ShareQRCode
                stashUser={props.stashUser}
                coLinkUser={props.coLinkUser}
                charityName={props.charityName}
                charityLogo={props.charityLogo}
                shareUrl={props.shareUrl}
                isOpen={showQr}
                onClose={() => setShowQr(false)}
              />
            ) : (
              <ShareQRCode
                stashUser={props.stashUser}
                charityName={props.charityName}
                charityLogo={props.charityLogo}
                shareUrl={props.shareUrl}
                isOpen={showQr}
                onClose={() => setShowQr(false)}
              />
            )}
          </>
        )}
      </div>
      <ToastContainer
        className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50 w-[10.5rem] h-[10.5rem] top-[17.65625rem]"
        toastClassName={() =>
          "w-full h-full bg-[rgba(72,72,72,0.7)] text-white flex items-center justify-center rounded-[20px] backdrop-filter-blur"
        }
      />
    </div>
  );
};
export default ShareComponent;
