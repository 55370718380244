import React, { useEffect } from "react";
import SponsorModal from "../../components/common/sponsorModal/sponsorModal";
import mixpanel from "../../services/mixpanelService";
interface SponsorModalProps {
  sponsorImageUrl?: string;
  brandName: string;
  impactScoreLevel?: string | null;
}

const MatchSponsor: React.FC<SponsorModalProps> = ({ sponsorImageUrl, brandName, impactScoreLevel }) => {
  useEffect(() => {
    mixpanel.track("brand-modal-viewed");
  }),
    [];

  return (
    <div className="justify-center items-center">
      <SponsorModal sponsorName={brandName} sponsorImageUrl={sponsorImageUrl || ""} impactScoreLevel={impactScoreLevel} />
    </div>
  );
};

export default MatchSponsor;
