import React from "react";
import { Link, useHistory } from "react-router-dom";
import { contactEmail, suggestEmail } from "../../utils/constants";
import { HamburgerMenuProps } from "./hamburgerMenu.types";
import Cross from "./../../assets/images/cross.svg";
import StashrunLogo from "./../../assets/images/Stashrun-beta-logo.svg";
import { routes } from "../../routes/routes.constant";
import useAuthStatus from "../../modules/campaign/hooks/useAuthStatus";
import mixpanel from "../../services/mixpanelService";

const HamburgerMenu: React.FC<HamburgerMenuProps> = (props: HamburgerMenuProps) => {
  const history = useHistory();
  const { isUserLoggedIn } = useAuthStatus();
  const navigateToCreate = () => {
    mixpanel.track("create-stashlink-clicked");
    if (isUserLoggedIn) {
      props.onClose();
      history.push({ pathname: routes.callback, state: { from: routes.colink, param: { isCreateStashLink: true } } });
    } else {
      history.push({ pathname: routes.onboarding, state: { from: routes.colink, param: { isCreateStashLink: true } } });
    }
  };

  return (
    <div className="fixed inset-0 bg-soft-black z-50 flex flex-col items-start p-6 z-[100]">
      <button className="text-white text-2xl mb-6 relative -top-3 -left-2" onClick={() => props.onClose()}>
        <img src={Cross} alt={Cross} className="w-6 h-6 mb-4" />
      </button>
      <nav className="flex flex-col h-full mt-3 justify-between">
        <div className="flex flex-col space-y-6">
          {props.isStashed && (
            <a onClick={navigateToCreate} className="font-primary font-bold text-2xl text-primary-l1">
              Create a Stashlink
            </a>
          )}
          <a
            onClick={() => mixpanel.track("suggest-clicked")}
            href={`mailto:${suggestEmail}`}
            className="font-primary font-bold text-2xl text-primary-l1">
            Suggest
          </a>
          <a
            onClick={() => mixpanel.track("contact-clicked")}
            href={`mailto:${contactEmail}`}
            className="font-primary font-bold text-2xl text-primary-l1">
            Contact us
          </a>
          <a onClick={() => mixpanel.track("about-clicked")} href="https://www.stashrun.com/about" target="_blank" className="font-primary font-bold text-2xl text-primary-l1" rel="noreferrer">
            About
          </a>
        </div>

        <div>
          <img src={StashrunLogo} alt={StashrunLogo} className="h-[18px] w-auto" />
          <p className="opacity-80 text-neutral-50 text-sm font-normal font-secondary leading-[18.20px] mt-3">
            Play to fund charities together with <br></br>friends and your favorite celebs.
          </p>
          <p className="mt-4 opacity-80 text-[#b0c1d3] text-xs font-normal font-['Inter'] leading-none">© 2024 Stashrun Inc</p>
          <p className="mt-1 opacity-80 text-[#b0c1d3] text-xs font-normal font-['Inter'] leading-none">Made with ❤️ from California</p>
          <p className="mt-1 opacity-80 text-[#b0c1d3] text-xs font-normal font-['Inter'] leading-none">
            <Link onClick={() => mixpanel.track("terms-clicked")} target="_blank" to="/terms" className="underline">
              Terms
            </Link>
            {"  "}•{"  "}
            <Link onClick={() => mixpanel.track("privacy-clicked")} target="_blank" to="/privacy" className="underline">
              Privacy
            </Link>
          </p>
        </div>
      </nav>
    </div>
  );
};

export default HamburgerMenu;
