import React from "react";
import ImpactBadge from "../common/impactBadges/impactBadges";
import { formatNumberWithUnits, generateCloudinaryImageUrl, getStyles } from "../../utils/common-function";
import { UserCardProps } from "./userCard.types";
import ShareIcon from "./../../assets/images/shareIcon.svg";
import VerifiedIcon from "../svgImages/verifiedIcon";
import mixpanel from "../../services/mixpanelService";

const UserCard: React.FC<UserCardProps> = (props: UserCardProps) => {
  const impactScore = formatNumberWithUnits(props.user?.impactScore || 0);

  const openShareModal = () => {
    props.setIsShareModalOpen && props.setIsShareModalOpen(true);
  };

  const styles = getStyles(props.impactScoreLevel);

  return (
    <div className="relative w-full flex flex-col items-center">
      <div className="relative w-full h-248 max-h-248">
        {!props.showIcons && (
          <div className="absolute w-full top-4 flex justify-center z-10">
           <div className="font-inter font-extrabold inter-I text-white text-13.77px backdrop-blur-5 tracking-0.964px w-105.93 h-33.105 py-8.21 px-17.87 flex items-center previewRotate justify-center leading-none rounded-8.21 border-1.39 border-white bg-black/30 shadow-previewShadow text-shadow-custom uppercase">Preview</div>
         </div>
        )}
        <img
          src={generateCloudinaryImageUrl(props.user?.profilePhoto || "", "750", "496")}
          alt={props.user?.fullName}
          className="w-full h-248 max-h-248 object-cover"
        />
        <div className="absolute top-2 h-full w-full bg-gradient-to-t from-black from-5% via-black/70 via-20% to-black/1 to-90%" />
      </div>
      <div className="text-center">
        {props.showIcons && (
          <>
            <span onClick={() => mixpanel.track("hero-impact-score-clicked")} className="absolute top-3 left-0">
              <ImpactBadge
                impact={impactScore}
                isImpactIconRequired
                setIsUserProfileModalOpen={props.setIsUserProfileModalOpen}
                impactScoreLevel={props.impactScoreLevel}
              />
            </span>
            <div className="p-1.5 bg-black/40 rounded-full flex justify-center items-center absolute top-3 right-3 backdrop-filter-blur w-7 h-7">
              <img
                src={ShareIcon}
                alt={ShareIcon}
                onClick={() => {
                  mixpanel.track("hero-share-clicked");
                  openShareModal();
                }}
              />
            </div>
          </>
        )}
        <div className="w-full h-9 py-2 pb-1 left-0 top-[212px] absolute justify-center items-end gap-1 inline-flex">
          <h1
            onClick={() => mixpanel.track("hero-name-clicked")}
            className="break-all w-fit text-28px font-primary font-extrabold z-10 px-2 -mt-10 items-start inline-block  capitalize leading-tight-29.54"
            style={{ color: styles.levelColor }}>
            {props.user?.fullName}
            {props.user?.isPublicProfile && (
              <VerifiedIcon color={styles.levelColor} className="ml-1.5 w-3 h-3 inline-block align-middle relative -top-2" />
            )}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
