import React from "react";
import MarqueeComponent from "../common/marquee/marqueeComponent";
import { convertToPercentage, getLocalStorage } from "../../utils/common-function";
import { PlayToFundProps } from "./playToFund.types";
import { marqueeTextIteration } from "../../utils/constants";
import { FaHeart } from "react-icons/fa";
import { useHistory } from "react-router";
import { routes } from "../../routes/routes.constant";
import { buttonTitle } from "../../modules/Accounts/Onboarding/constant";
import { formatCurrencyToUsd } from "../../utils/format";
import FollowingCheck from "./../../assets/images/follow-check.svg";
import Button from "../common/button";
import useColinkExist from "../../services/hooks/useColinkExist";
import { Stashlink } from "../../types";
import ProgressBar from "../common/progressBar";
import mixpanel from "../../services/mixpanelService";

const PlayToFund: React.FC<PlayToFundProps> = (props: PlayToFundProps) => {
  const {
    campaign,
    setIsShareModalOpen,
    isCampaignFunded,
    isUserFollowing,
    isCurrentUserStashLink,
    isUserLoggedIn,
    followCelebrity,
    celebrityName,
    onToggle,
    userProfile
  } = props;
  const gamePlayCount = getLocalStorage("gamePlayCount") as number;
  const percentageCompleted = convertToPercentage(campaign?.currentAmount, campaign?.goalAmount);
  const navigation = useHistory();
  const stashlink = getLocalStorage("stashlink") as Stashlink;
  const { colinkExistData } = useColinkExist(stashlink?.id || "");

  const openShareModal = () => {
    setIsShareModalOpen && setIsShareModalOpen(true);
  };

  return (
    <div className="fixed bottom-0 w-full bg-black/70 backdrop-blur pb-4 pt-0">
      <div className="flex items-center justify-between w-full py-1.5">
        {!isCurrentUserStashLink &&
          (isCampaignFunded ? (
            <MarqueeComponent text="Be the first to know" iterationsPerScreen={marqueeTextIteration} separatorIcon={FaHeart} />
          ) : (
            <MarqueeComponent text="you play = sponsors pay" iterationsPerScreen={marqueeTextIteration} separatorIcon={FaHeart} />
          ))}
        {isCurrentUserStashLink && isCampaignFunded && (
          <div className="py-1.5">
            <MarqueeComponent text="GOAL REACHED" iterationsPerScreen={marqueeTextIteration} separatorIcon={"🎉"} />
          </div>
        )}
      </div>
      <div className="px-4">
        <div className="mx-2">
          <ProgressBar percentageCompleted={percentageCompleted} />
        </div>

        {isCampaignFunded ? (
          <div className="text-center mt-1">
            <div className="mb-1">
              <span className="font-primary font-semibold text-xl">{formatCurrencyToUsd(campaign?.goalAmount)}</span>
              <span role="img" aria-label="party" className="mx-3">
                🎉
              </span>
              <span className="text-primary-l1 text-base font-bold font-secondary">GOAL REACHED!</span>
            </div>
            {isUserLoggedIn ? (
              isCurrentUserStashLink ? (
                <></>
              ) : (
                // <div className="w-full gap-8.01 bg-primary-l1/30 pb-1.5 rounded-lg mt-3">
                //   <button
                //     className="w-full px-4 py-3 text-base font-primary tracking-wider font-wght-750 gap-2 rounded-lg text-black bg-primary-l1 border-2 border-black"
                //     onClick={() => {
                //       mixpanel.track("bottom-bar-share-button-clicked");
                //       openShareModal();
                //     }}>
                //     {buttonTitle.share}
                //   </button>
                // </div>
                <>
                  {isUserFollowing ? (
                    <div className="w-full gap-2.5 pb-1.5 rounded-lg mt-3">
                      <button
                        className="w-full px-4 py-3 text-base font-primary tracking-wider font-wght-750 rounded-lg text-grey bg-grey-bg border-2 border-grey flex align-center justify-center"
                        onClick={() => {
                          mixpanel.track("following-button-clicked");
                          navigation.push("/");
                        }}>
                        Following
                        <span className="ml-1">
                          <img src={FollowingCheck} alt="Following Check"></img>
                        </span>
                      </button>
                    </div>
                  ) : (
                    <Button
                      onClick={() => {
                        mixpanel.track("follow-button-clicked");
                        followCelebrity();
                      }}
                      isDisabled={false}
                      title={`Follow ${celebrityName?.split(" ")[0]}`}
                    />
                  )}
                </>
              )
            ) : (
              <div className="w-full mt-1">
                <Button
                  onClick={() => {
                    mixpanel.track("follow-button-clicked");
                    navigation.push({
                      pathname: routes.onboarding,
                      state: {
                        userName: celebrityName,
                        followerProfileImage: userProfile,
                        param: { isFollow: true, stashLinkId: stashlink.id }
                      }
                    });
                  }}
                  isDisabled={false}
                  title={`Follow ${celebrityName?.split(" ")[0]}`}
                />
              </div>
            )}
          </div>
        ) : (
          <>
            {isCurrentUserStashLink ? (
              <div className="text-center mt-3">
                <span className="text-base-white font-semibold font-primary text-xl">{formatCurrencyToUsd(campaign?.currentAmount)}</span>
                <span className="text-primary-l1 font-bold font-secondary mx-3">raised of {formatCurrencyToUsd(campaign?.goalAmount)}</span>
                <div className="w-full gap-8.01 bg-primary-l1/30 pb-1.5 rounded-lg mt-3">
                  <button
                    className="w-full px-4 py-3 text-base font-primary tracking-wider font-wght-750 gap-2 rounded-lg text-black bg-primary-l1 border-2 border-black"
                    onClick={() => {
                      mixpanel.track("bottom-bar-share-button-clicked");
                      openShareModal();
                    }}>
                    {buttonTitle.share}
                  </button>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-12 gap-2 flex content-center justify-between items-center mt-3">
                <div className="flex flex-col col-span-5 content-center">
                  <p className="text-26px font-primary leading-8 font-bold text-neutral-50">
                    {formatCurrencyToUsd(campaign?.currentAmount)}
                  </p>
                  <p className="text-sm leading-5 font-secondary font-normal text-neutral-50">
                    raised of {formatCurrencyToUsd(campaign?.goalAmount)}
                  </p>
                </div>
                {gamePlayCount > 1 ? (
                  <div className="w-full col-span-7 pb-1.5 rounded-lg">
                    {colinkExistData ? (
                      <button className="w-full px-4 py-3 text-base font-primary tracking-wider font-wght-750 rounded-lg text-grey bg-grey-bg border border-grey flex align-center justify-center">
                        Teamed Up{" "}
                        <span className="ml-0">
                          <img src={FollowingCheck} alt="Following Check"></img>
                        </span>
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          if (isUserLoggedIn) {
                            mixpanel.track("team-up-button-clicked");
                            navigation.push({ pathname: routes.callback, state: { from: routes.colink } });
                          } else {
                            mixpanel.track("sign-up-button-clicked");
                            navigation.push({
                              pathname: routes.onboarding,
                              state: { from: location.pathname, userName: celebrityName, followerProfileImage: userProfile }
                            });
                          }
                        }}
                        className={`w-full px-4 py-3 font-primary text-base leading-tight  tracking-wider font-wght-750 gap-2 rounded-lg text-black border-2 border-black ${
                          isUserLoggedIn ? "bg-primary-l1 shadow-[0px_4px_0px_#6C801F]" : "bg-base-white shadow-[0px_4px_0px_#969696]"
                        }`}>
                        {isUserLoggedIn ? buttonTitle.teamUp : buttonTitle.signUp}
                      </button>
                    )}
                  </div>
                ) : (
                  <div className="w-full col-span-7 bg-primary-l1/30 pb-1 rounded-lg">
                    <button
                      onClick={() => onToggle()}
                      className="w-full px-4 py-3 text-base font-primary font-wght-750 leading-tight gap-2 rounded-lg text-black bg-primary-l1 border-2 border-black">
                      {buttonTitle.playToFund}
                    </button>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PlayToFund;
