import { toast } from "react-toastify";
import mixpanel from "../services/mixpanelService";

export const copyLink = (shareUrl: string) => {
  mixpanel.track("copy-link-button-clicked");
  navigator.clipboard
    .writeText(shareUrl)
    .then(() => {
      toast.success("Link copied!");
    })
    .catch((err) => {
      console.error("Failed to copy: ", err);
    });
};

export const saveImage = (imgRef: any) => {
  mixpanel.track("image-copied");
  const canvas = imgRef?.current?.querySelector("canvas");
  if (canvas) {
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qr-code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
};

export const sendSMS = (shareUrl: string) => {
  mixpanel.track("sms-button-clicked");
  window.location.href = `sms:?body=${encodeURIComponent(shareUrl)}`;
};
